@import "~dyl-components/config.scss";

.icon.Profile__controls {
    color: $grey-1;

    margin-left: 0.75em;
    margin-right: 0.75em;

    &:hover {
        color: $primary;
    }

    &:active {
        color: rgba(41, 121, 255, 0.25);
        height: 30px;
        width: 30px;
        line-height: 29px;
        border-radius: 5px;
        border: 1px solid #C7D6F0;
        background-color: #e1ebff;
    }

    &--less-margin {
        margin-left: 0.65em;
        margin-right: 0.65em;

    }
}

.SubControls {
    padding-top: 15px;
    padding-bottom: 15px;
}