@import "~dyl-components/config.scss";

$carousel-top: 45%;

.FundedCustomerData {
    &__customer {
        margin-top: 0.5em;
        margin-right: 0.5em;
        height: 65vh;
    }

    &__admin {
        height: 65vh;
    }
}

.icon.FundedCustomerData__dot-inactive {
    opacity: 0.25;

    &:hover {
        opacity: 0.25;
    }
}

.icon.slick-prev {
    position: absolute;
    top: $carousel-top;

    &::before {
        color: $black;
    }
}

.icon.slick-next {
    position: absolute;
    top: $carousel-top;
    
    &::before {
        color: $black;
    }
}
