@import "~dyl-components/config.scss";

.SignatureCanvasForm {
    width: 80%;
    height: 80%;
    margin: 0 auto;
    background: $grey-6;

    &__canvas {
        width: 100%;
        height: 100%;
    }
}
