@import 'dyl-components/config.scss';

.ui.pointing.label.ExternalNumbers__errorLabel {
    white-space: normal;
    background-color: $primary-error-4;
    color: $primary-error-1;
    border: $primary-error-1 1px solid;
    padding: 7px 25px 7px 25px;
    margin-bottom: 14px;
    margin-top: 0;
}