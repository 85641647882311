@import "~dyl-components/config.scss";

.TermsOfServiceWrapper {
    height: 100vh; 
    overflow: hidden;
    background: $grey-6;
}

.ui.segment.TermsOfService {
    margin: 0 auto;
    margin-top: 5em;
    margin-bottom: 0;

    margin-bottom: 0;
    max-width: 80%;
    height: 80%;
}

.TermsOfService__signature {
    background-size: 200px 50px;
    width: 200px;
    height: 50px;
    margin-top: 1em;
}
