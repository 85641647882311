@import "dyl-components/config.scss";

.DropdownIcon {
    margin: 0 10px 0 0;
}

.UserAccountsPanel {
    .ui.segments.TableWithHeader {
        border-style: none;
        box-shadow: none;

        .ui.segment.TableWithHeader__header {
            background-color: white;
            border-radius: 0px;
            padding: 0px 0px 20px;

            .UserOptions {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                .SearchBar__SearchInput > div > input {
                    width: 450px;
                }

                &__NewUserButton {
                    width: 180px;
                    margin-right: 0px;
                    color: white;
                    background-color: $primary-table-6;
                }
            }
        }

        .ui.segment.TableWithHeader__table {
            border-style: none;
            border-radius: 0px;

            .ui.segments {
                border-style: none;
                border-radius: 0px;
                box-shadow: none;

                .ui.segment {
                    border-style: none;
                    border-radius: 0px;

                    .ui.striped.table {
                        thead tr.Table__row th {
                            padding-top: 20px;
                            padding-bottom: 20px;
                            background-color: $primary-table-6;
                            color: white;
                        }
                    }
                }

                .ui.segment.right {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
        }
    }
}

.ui.grid > .row {
    .column.LineAssignmentButtonColumn {
        top: 28px;
    }
}

.ui.grid > .row.LineAssignmentFirstRow {
    padding-bottom: 0;
}
.ui.grid > .row.LineAssignmentSecondRow {
    padding-top: 0;
}
.ui.grid > .row.LineAssignmentThirdRow {
    padding-bottom: 0;
}

.ui.input > input {
    width: 15em;
}

.ui.selection.dropdown.LineAssignmentExtension {
    width: 15em;
}

.ui.segment.UserAccountsTable {
    padding-left: 30px;
}
