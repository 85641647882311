.ExternalNumbers__verifyField {
    flex: 1
}

.ExternalNumbers__hugeField {
    flex: 1;

    .ui.huge.input > input {
        text-align: center;
    }
}