@import '~dyl-components/config.scss';

.Location {
    color: $primary-table-7;
    border: 1px solid $primary-table-7;
    background-color: $secondary-6-b;
    border-radius: 0.5em;

    &:focus,
    &:hover {
      color: $primary-table-7;
      background-color: $primary-table-8;
    }

    padding: 0.5em;

    &__billing-shipping {
      margin-top: 0.5em;

      .ui.checkbox {
        margin-right: 0.5em;
      }
    }
}
