@import "dyl-components/config.scss";

.UploadsTable {
    .ui.segment.TableWithHeader__table {
        border-style: none;
        border-radius: 0px;

        .ui.segments {
            border-style: none;
            border-radius: 0px;
            box-shadow: none;

            .ui.segment {
                border-style: none;
                border-radius: 0px;
            }

            .ui.segment.right {
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }

    .ui.segments.TableWithHeader {
        border-style: none;
        box-shadow: none;

        .ui.segment.TableWithHeader__header {
            background-color: $white;
            border-radius: 0px;
            padding: 0px 0px 20px;
        }

        .RecordsTable div.RecordsTable__TableSegment div.TableSegment__OverflowContainer {
            overflow-x: visible;
        }
    }

    .segments.RecordsTable div.RecordsTable__TableSegment div.TableSegment__OverflowContainer {
        overflow-x: visible;
    }
}
